<template>
  <div class="mt-5">
    <div v-if="!loading">
      <h2 class="mb-2">{{ translator("commercialDataTitle") }}</h2>

      <v-container v-if="settingLoaded" fluid>
        <v-row>
          <v-col>
            <TextFieldSetting
              :label="translator('companyBusinessName')"
              :value="companyBusinessName"
              settingKey="companyBusinessName"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col>
            <TextFieldSetting
              :label="translator('companyWebSite')"
              :value="companyWebSite"
              settingKey="companyWebSite"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <TextFieldSetting
              :label="translator('companyAddress')"
              :value="companyAddress"
              settingKey="companyAddress"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col cols="3">
            <TextFieldSetting
              :label="translator('companyPostalCode')"
              :value="companyPostalCode"
              settingKey="companyPostalCode"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          
          <v-col cols="3">
            <TextFieldSetting
              :label="translator('companyPhone')"
              :value="companyPhone"
              settingKey="companyPhone"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <TextFieldSetting
              :label="translator('salonName')"
              :value="salonName"
              settingKey="salonName"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col cols="3">
            <TextFieldSetting
              :label="translator('nameForTicket')"
              :value="nameForTicket"
              settingKey="nameForTicket"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          
          <v-col cols="3">
            <TextFieldSetting
              :label="translator('lastNameForTicket')"
              :value="lastNameForTicket"
              settingKey="lastNameForTicket"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TextFieldSetting
              :label="translator('companyNationality')"
              :value="companyNationality"
              settingKey="companyNationality"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col cols="3">
            <TextFieldSetting
              :label="translator('companyCity')"
              :value="companyCity"
              settingKey="companyCity"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>

          <v-col cols="3">
            <TextFieldSetting
              :label="translator('companyProvince')"
              :value="companyProvince"
              settingKey="companyProvince"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <TextFieldSetting
              :label="translator('companyEmailPec')"
              :value="companyEmailPec"
              settingKey="companyEmailPec"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col>
            <TextFieldSetting
              :label="translator('companyEmail')"
              :value="companyEmail"
              settingKey="companyEmail"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col>
            <TextFieldSetting
              :label="translator('companyVATNumber')"
              :value="companyVATNumber"
              settingKey="companyVATNumber"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col>
            <TextFieldSetting
              :label="translator('companyFiscalCode')"
              :value="companyFiscalCode"
              settingKey="companyFiscalCode"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TextFieldSetting
              label="Numero Amministratore"
              :value="adminNumber"
              settingKey="adminNumber"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
          <v-col>
            <TextFieldSetting
              label="Nome Amministratore"
              :value="adminName"
              settingKey="adminName"
              @set="setSetting"
            >
            </TextFieldSetting>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import settingService from "@/services/settings/settings.service.js";
import Vue from "vue";
import * as components from "../generalSettingComponents";

export default {
  name: "CompanyCommercialData",
  components: { ...components },
  data: function () {
    return {
      loading: false,
      settings: [],
      companyBusinessName: undefined,
      companyAddress: undefined,
      companyPostalCode: undefined,
      companyCity: undefined,
      commercialDataTitle: undefined,
      companyVATNumber: undefined,
      companyFiscalCode: undefined,
      companyEmailPec: undefined,
      companyEmail: undefined,
      companyProvince: undefined,
      companyNationality: undefined,
      companyPhone: undefined,
      adminNumber: undefined,
      adminName: undefined,
      nameForTicket: undefined,
      lastNameForTicket: undefined,
      salonName: undefined
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    translator(settingKey) {
      return this.$translations.t("settings." + settingKey + ".title");
    },
    loadSettings: async function () {
      this.loading = false;
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    setSetting: function (key, value) {
      this.$settings.set(key, value).then(() => {
        this.loadSettings();
      });
    },
  },
  computed: {
    settingLoaded() {
      return this.settings.length > 0;
    },
  },
  settings: {
    companyBusinessName: {
      bind: "companyBusinessName",
    },
    companyWebSite: {
      bind: "companyWebSite",
    },
    companyAddress: {
      bind: "companyAddress",
    },
    companyPostalCode: {
      bind: "companyPostalCode",
    },
    companyProvince: {
      bind: "companyProvince",
    },
    companyCity: {
      bind: "companyCity",
    },
    companyNationality: {
      bind: "companyNationality",
    },
    commercialDataTitle: {
      bind: "commercialDataTitle",
    },
    companyVATNumber: {
      bind: "companyVATNumber",
    },
    companyFiscalCode: {
      bind: "companyFiscalCode",
    },
    companyEmailPec: {
      bind: "companyEmailPec",
    },
    companyEmail: {
      bind: "companyEmail",
    },
    companyPhone:{
      bind:"companyPhone"
    },
    adminNumber: {
      bind: "adminNumber",
    },
    adminName: {
      bind: "adminName",
    },
    nameForTicket: {
      bind: "nameForTicket",
    },
    lastNameForTicket: {
      bind: "lastNameForTicket",
    },
    salonName: {
      bind: "salonName",
    },
  },
};
</script>